export const FONTS = {
  PoppinsRegular: "Poppins-Regular",
  PoppinsBlack: "Poppins-Black",
  PoppinsLight: "Poppins-Light",
  PoppinsMedium: "Poppins-Medium",
  PoppinsSemiBold: "Poppins-SemiBold",
};

export const API = {
  baseurl: "https://nutrisearchapp.com/nutrisearch/",
  login: "login",
  register: "registration",
  nutriproduct: "getproduct",
  getprofile: "get_profile",
  profileupdate: "profileUpdate",
  logout: "logout",
  Subscription: "Subscription",
  changepassword: "change_password",
  forgetpassword: "forget_password",
  getproduct: "getproduct",
  getEbook: "getbook",
  faq: "showFaq",
  bulksubscription: "getbulkplanpricing",
  pdfGenrate: "bookpdf",
  getsubscription: "getSubscription",
  upgradesubscription: "upgradesubscription",
};

export const COLORS = {
  black: "#222324",
  white: "#fff",
  primary: "#2C648E",
  primaryLite: "#4B8ABA",
  primarybordercolor: "#E2E2E2",
  primarygraycolor: "#6F7479",
  primaryBackgroundColor: "#FCFCFC",
  maroon: "#8D0706",
  darkbordercolor: "#6F7479",
};

const images = {
  flags: {
    korea: require("./src/assets/images/south-korea.png"),
    france: require("./src/assets/images/france.png"),
    china: require("./src/assets/images/china.png"),
    england: require("./src/assets/images/england.png"),
    spain: require("./src/assets/images/spain.png"),
  },
  icons: {
    dot: require("./src/assets/images/dot.png"),
    language: require("./src/assets/images/language.png"),
    faq: require("./src/assets/images/faq.png"),
    request: require("./src/assets/images/request.png"),

    ArrowDown: require("./src/assets/images/ArrowDown.png"),
    //   activeAccount: require('../../assets/images/ActiveAccount.png'),
    //   logout: require('../../assets/images/logout.png'),
    //   fillDot: require('../../assets/images/filldot.png'),
    //   unfillDot: require('../../assets/images/unfilldot.png'),
    //   crossCircle: require('../../assets/images/crosscircle.png'),
    //   passwordCloseEye: require('../../assets/images/passwordcloseeye.png'),
    //   camera: require('../../assets/images/camera.png'),
    //   mobile: require('../../assets/images/Mobile.png'),
    //   browser: require('../../assets/images/Browserr.png'),
    //   logoutQuestionIcon: require('../../assets/images/logoutQuestionIcon.png'),
    //   selector: require('../../assets/images/imgselector.png'),
    //   eye: require('../../assets/images/blackeye.png'),
  },
  defaultAvatar:
    "https://thumbs.dreamstime.com/b/default-avatar-profile-image-vector-social-media-user-icon-potrait-182347582.jpg",
};

export default images;
