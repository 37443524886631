import {
  View,
  Text,
  StyleSheet,
  Dimensions,
  Image,
  TouchableOpacity,
  StatusBar,
  ActivityIndicator,
  ScrollView,
  Platform,
  Pressable,
  Alert,
  Keyboard,
  Animated,
  FlatList,
} from "react-native";
import React, { useState, useEffect, useRef } from "react";
import { TextInput } from "react-native";
import Checkbox from "expo-checkbox";
import AsyncStorage from "@react-native-async-storage/async-storage";
import * as WebBrowser from "expo-web-browser";
import * as Google from "expo-auth-session/providers/google";
import { Toast } from "react-native-toast-notifications";
import { BlurView } from "expo-blur";
import images, { API, COLORS, FONTS } from "../../../utils";
import DeviceInfo, {
  getUniqueId,
  getManufacturer,
} from "react-native-device-info";
import i18next, { languageResources } from "../../../services/i18next";
import { useTranslation } from "react-i18next";
const { width } = Dimensions.get("screen");
WebBrowser.maybeCompleteAuthSession();
const Signin = ({ navigation }) => {
  const [isVisible3, setIsVisible3] = useState(false);
  const [deviceUid, setDeviceUid] = useState("");
  const [manufacture, setManufacture] = useState("");
  const [deviceName, setDeviceName] = useState("");
  const [accessToken, setAccessToken] = useState(null);
  const { t } = useTranslation();
  const logoimg = require("../../assets/images/logoimg.png");
  const PasswordEye = require("../../assets/images/eye-outline.png");
  const Passwordcloseye = require("../../assets/images/passwordcloseeye.png");
  const Google1 = require("../../assets/images/google.png");
  const crossCircle = require("../../assets/images/crosscircle.png");
  const UnfillDot = require("../../assets/images/unfilldot.png");
  const FillDot = require("../../assets/images/filldot.png");

  const [isChecked, setChecked] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [timezone, setTimezone] = useState("");
  const [DeviceToken, setDeviceToken] = useState("");
  const [userCredential, setUserCredential] = useState(false);
  const [storageEmail, setStorageEmail] = useState("");
  const fadeAnimm = useRef(new Animated.Value(0)).current;
  const [visible, setVisible] = useState(false);
  const changeLng = (lng) => {
    i18next.changeLanguage(lng);
    setVisible(false);
    handleSelectedLanguage(lng);
  };

  const hidePopup3 = () => {
    Animated.timing(fadeAnimm, {
      toValue: 0,
      duration: 300,
      useNativeDriver: true,
    }).start(() => setIsVisible3(false));
  };
  const [request, response, promptAsync] = Google.useAuthRequest({
    clientId:
      // "183309621247-5ifm14idhro5re12lvq1n41ua0r2ee7q.apps.googleusercontent.com",
      "183309621247-7seloar2ac9gj5vl58d1lctd0fao1vsp.apps.googleusercontent.com",
    iosClientId:
      "183309621247-5ifm14idhro5re12lvq1n41ua0r2ee7q.apps.googleusercontent.com",
    androidClientId:
      "183309621247-c2bm6163v6i3k29e5mao0apjqlu8eetv.apps.googleusercontent.com",
    // redirectUri: "https://nutrisearchapp.com",
  });
  useEffect(() => {
    getStoredCredentials();
    if (Platform.OS == "web") {
      getBrowser();
    } else {
      DeviceInfo.getUniqueId()
        .then((id) => {
          //   console.log('Unique ID: >>>>', id);
          setDeviceUid(id);
        })
        .catch((error) => {
          console.error("Error getting Unique ID:", error);
        });

      // Get Manufacturer and log it to the console
      DeviceInfo.getManufacturer()
        .then((name) => {
          //   console.log('Manufacturer: >>>>', name);
          setManufacture(name);
        })
        .catch((error) => {
          console.error("Error getting Manufacturer:", error);
        });

      // Get Device Name and log it to the console
      DeviceInfo.getDeviceName()
        .then((name) => {
          //   console.log('Device Name: >>>>', name);
          setDeviceName(name);
        })
        .catch((error) => {
          console.error("Error getting Device Name:", error);
        });
    }
  }, []);
  useEffect(() => {
    if (accessToken) {
      fetchUserInfo();
    }
  }, [accessToken]);
  useEffect(() => {
    if (response?.type === "success") {
      setAccessToken(response.authentication.accessToken);
    }
  }, [response]);
  useEffect(() => {
    fetchToken();
  }, []);
  useEffect(() => {
    const getTimezone = () => {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      setTimezone(timezone);
    };
    getTimezone();
  }, []);

  const getStoredCredentials = async () => {
    try {
      const email = await AsyncStorage.getItem("email");
      const password = await AsyncStorage.getItem("Password");
      if (email != "" && password != "") {
        setUserCredential(true);
        setEmail(email);
        setStorageEmail(email);
        setPassword(password);
      } else {
        console.log("No stored credentials found");
      }
    } catch (e) {
      console.log("Error retrieving credentials:", e);
    }
  };
  const handleRememberMeAlways = async (email, password) => {
    try {
      await AsyncStorage.setItem("email", email);
      await AsyncStorage.setItem("Password", password);
    } catch (e) {
      console.log("error", e);
    }
  };
  function getBrowser() {
    const userAgent = navigator.userAgent;
    const vendor = navigator.vendor;
    let browserName = "";

    if (/Chrome/.test(userAgent) && /Google Inc/.test(vendor)) {
      browserName = "Chrome";
    } else if (/Safari/.test(userAgent) && /Apple Computer/.test(vendor)) {
      if (!/Chrome/.test(userAgent)) {
        browserName = "Safari";
      }
    } else if (/Firefox/.test(userAgent)) {
      browserName = "Firefox";
    } else if (/MSIE|Trident/.test(userAgent)) {
      browserName = "Internet Explorer";
    } else if (/Edge/.test(userAgent)) {
      browserName = "Edge";
    }
    setDeviceName(browserName);
    console.log(">>>", browserName);
  }
  const fetchToken = async () => {
    try {
      const value = await AsyncStorage.getItem("DeviceToken");
      if (value !== null) {
        console.log(">> token >>" + value);
        setDeviceToken(value);
      }
    } catch (e) {
      console.log(JSON.stringify(e));
    }
  };
  const fetchUserInfo = async () => {
    const response = await fetch("https://www.googleapis.com/userinfo/v2/me", {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    const user = await response.json();
    googleLogin(user);
  };
  const storeData = async (key, id) => {
    try {
      await AsyncStorage.setItem("key", key);
      await AsyncStorage.setItem("id", id);
    } catch (e) {
      console.log("error", e);
    }
  };
  const googleLogin = (userInfo) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Basic YWRtaW46MTIzNA==");
    const formdata = new FormData();
    formdata.append(
      "device_name",
      Platform.OS === "web" ? deviceName : `${manufacture} ${deviceName}`
    );
    formdata.append("device_uuid", Platform.OS != "web" ? deviceUid : "null");
    formdata.append(
      "device_type",
      Platform.OS == "ios" ? "1" : Platform.OS == "android" ? "2" : "3"
    );
    formdata.append("social_type", "1");
    formdata.append("device_token", Platform.OS != "web" ? "111" : "74893274");
    formdata.append("email", userInfo.email);
    formdata.append("social_id", userInfo.id);
    formdata.append("login_type", "1");
    formdata.append("name", userInfo.name);
    formdata.append("username", userInfo.given_name);
    formdata.append("created_at", timezone);
    formdata.append("phone", "");
    formdata.append("country_code", "US");
    fetch(`${API.baseurl}/social_login`, {
      method: "POST",
      headers: myHeaders,
      body: formdata,
    })
      .then((response) => response.json())
      .then((result) => handleLoginResponse(result))
      .catch((error) => handleError(error));
  };
  const handleLoginResponse = (result) => {
    if (result?.status === 200) {
      if (isChecked) {
        handleRememberMeAlways(email, password);
      } else {
        let email = "";
        let password = "";
        handleRememberMeAlways(email, password);
      }
      storeData(result.data?.session_key, result.data?.id);
      setIsLoading(false);
      notifyMessage(result.message);
      setIsSubmitting(false);
      if (result.data?.is_subscribe === "0") {
        navigation.replace("Subscription");
      } else {
        navigation.replace("Home2");
      }
      resetForm();
    } else {
      setIsLoading(false);
      notifyMessage(result?.message);
      setIsSubmitting(false);
    }
  };
  const handleError = (error) => {
    console.log("error", error);
    setIsLoading(false);
    notifyMessage(error?.message);
    setIsSubmitting(false);
  };
  const validateInputs = () => {
    let isValid = true;
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    if (!email) {
      setEmailError("Enter email address");
      isValid = false;
    } else if (!emailRegex.test(email)) {
      setEmailError("Enter a valid email address");
      isValid = false;
    } else {
      setEmailError("");
    }
    if (!password) {
      setPasswordError("Enter your Password");
      isValid = false;
    } else {
      setPasswordError("");
    }
    return isValid;
  };
  const handleSubmit = () => {
    if (!validateInputs()) {
      console.log("Error", "Please fill in all fields correctly");
      return;
    }
    setIsLoading(true);
    handleLogin();
  };
  const handleLogin = () => {
    setIsSubmitting(true);
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Basic YWRtaW46MTIzNA==");
    const formdata = new FormData();
    formdata.append("email", email);
    formdata.append("password", password);
    // formdata.append("device_token", DeviceToken);
    formdata.append(
      "device_token",
      Platform.OS != "web" ? "65656" : "74893274"
    );
    // formdata.append("device_type", "1");
    formdata.append(
      "device_type",
      Platform.OS == "ios" ? "1" : Platform.OS == "android" ? "2" : "3"
    );
    // formdata.append("device_name",  Platform.OS == "web" ?deviceName:manufacture+deviceName);
    formdata.append(
      "device_name",
      Platform.OS === "web" ? deviceName : `${manufacture} ${deviceName}`
    );
    formdata.append("device_uuid", Platform.OS != "web" ? deviceUid : "null");
    formdata.append("created_at", timezone);

    fetch(`${API.baseurl}${API.login}`, {
      method: "POST",
      headers: myHeaders,
      body: formdata,
    })
      .then((response) => response.json())
      .then((result) => handleLoginResponse(result))
      .catch((error) => handleError(error));
  };
  const notifyMessage = (msg) => {
    if (Platform.OS === "ios") {
      Alert.alert(msg);
    } else {
      Toast.show(msg, {
        type: "normal",
        placement: "bottom",
        duration: 2000,
        offset: 0,
        animationType: "zoom-in",
      });
    }
  };
  const resetForm = () => {
    setEmail("");
    setEmailError("");
    setPassword("");
    setPasswordError("");
  };
  const currentLanguage = i18next.language;
  useEffect(() => {
    setSelectedItem(currentLanguage);
  }, []);

  const handleSelectedLanguage = async (value) => {
    try {
      await AsyncStorage.setItem("Language", value);
    } catch (e) {
      console.log(JSON.stringify(e));
    }
  };

  const handleEmailChange = async (text) => {
    setEmail(text);
    if (text != storageEmail) {
      setUserCredential(false);
    } else {
      setUserCredential(true);
    }
  };
  const [selectedItem, setSelectedItem] = useState("");

  const showPopup3 = () => {
    Animated.timing(fadeAnimm, {
      toValue: 1,
      duration: 300,
      useNativeDriver: true,
    }).start();
    setIsVisible3(true);
  };
  const togglePasswordSection2 = () => {
    setPopupVisible(!isPopupVisible);
  };
  const [isPopupVisible, setPopupVisible] = useState(false);

  return (
    <ScrollView
      keyboardShouldPersistTaps="handled"
      style={{ flex: 1, backgroundColor: "white" }}
    >
      <View style={styles.container}>
        <StatusBar
          animated
          barStyle="light-content"
          backgroundColor="transparent"
          translucent
        />
        <Pressable
          onPress={() => {
            showPopup3();
          }}
          style={{
            alignSelf: "flex-end",
            marginTop: Platform.OS == "web" ? 10 : 30,
            marginRight: 16,
          }}
        >
          <Text
            style={{
              color: COLORS.primary,
              fontSize: 17,
              fontFamily: "Poppins-SemiBold",
            }}
          >
            {t("Language")}
          </Text>
        </Pressable>
        <View style={styles.headerView}>
          <Pressable
            onPress={() => navigation.goBack()}
            style={styles.backButton}
          />
          <Image source={logoimg} style={styles.logo} />
        </View>
        <Text style={styles.welcomeText}>{t("Hey Welcome!")}</Text>
        <Text style={styles.instructionsText}>
          {t("Please enter your details to login")}
        </Text>
        <View
          style={{
            width: Platform.OS === "web" ? "50%" : width - 92,
            maxWidth: 436,
            minWidth: 325,
            alignItems: "center",
            borderWidth: 0,
          }}
        >
          <TextInput
            autoCapitalize="none"
            keyboardType="ascii-capable"
            style={[styles.input, Platform.OS === "web" && styles.inputWeb]}
            placeholder={t("Email")}
            placeholderTextColor={COLORS.primarygraycolor}
            value={email}
            onChangeText={handleEmailChange}
            // onChangeText={setEmail,setStorageEmail}
          />
        </View>

        <View
          style={{
            width: Platform.OS === "web" ? "50%" : width - 92,
            maxWidth: 436,
            minWidth: 325,
            borderWidth: 0,
          }}
        >
          {emailError ? (
            <Text style={styles.errorText}>{emailError}</Text>
          ) : null}
        </View>

        <View style={styles.passwordContainer}>
          <TextInput
            style={[styles.input, styles.passwordInput]}
            placeholder={t("Password")}
            placeholderTextColor={COLORS.primarygraycolor}
            value={password}
            onChangeText={setPassword}
            secureTextEntry={isActive}
          />
          <Pressable
            onPress={() => setIsActive(!isActive)}
            style={styles.passwordToggle}
          >
            <Image
              source={isActive ? Passwordcloseye : PasswordEye}
              style={styles.toggleImage}
            />
          </Pressable>
        </View>

        <View
          style={{
            width: Platform.OS === "web" ? "50%" : width - 92,
            maxWidth: 436,
            minWidth: 325,
            borderWidth: 0,
          }}
        >
          {passwordError ? (
            <Text style={styles.errorText}>{passwordError}</Text>
          ) : null}
        </View>

        <View style={styles.rememberMeContainer}>
          <Checkbox
            value={isChecked}
            onValueChange={setChecked}
            color={isChecked ? COLORS.primary : undefined}
            style={styles.checkbox}
          />
          <Text style={styles.rememberMeText}>{t("Remember me always")}</Text>
        </View>

        <TouchableOpacity
          onPress={() => navigation.navigate("ForgotPassword")}
          style={styles.forgotPassword}
        >
          <Text style={styles.forgotPasswordText}>
            {t("Forgot your password ?")}
          </Text>
        </TouchableOpacity>

        <TouchableOpacity
          onPress={handleSubmit}
          activeOpacity={0.8}
          style={styles.signInButton}
        >
          {isLoading ? (
            <ActivityIndicator color="white" />
          ) : (
            <Text style={styles.signInButtonText}>{t("Sign In")}</Text>
          )}
        </TouchableOpacity>

        <View style={styles.orContainer}>
          <View style={styles.divider} />
          <Text style={styles.orText}>{t("or continue with")}</Text>
          <View style={styles.divider} />
        </View>

        <Pressable onPress={() => promptAsync()} style={styles.googleButton}>
          <Image source={Google1} style={styles.googleIcon} />
          <Text style={styles.googleButtonText}>{t("Login with Google")}</Text>
        </Pressable>

        <TouchableOpacity
          onPress={() => navigation.navigate("SignUp")}
          style={styles.signUpPrompt}
        >
          <Text style={styles.signUpText}>
            {t("Don’t have an account ? ")}{" "}
            <Text style={styles.signUpLink}>{t("Sign up")}</Text>
          </Text>
        </TouchableOpacity>
        <Text
          style={{
            color: COLORS.primarygraycolor,
            fontFamily: FONTS.PoppinsRegular,
            fontSize: 13,
          }}
        >
          Version: 1.0
        </Text>
      </View>
      {isVisible3 && (
        <>
          <BlurView
            style={styles.blurBackground}
            blurType="light"
            intensity={20}
            reducedTransparencyFallbackColor="white"
          />
          <Animated.View style={[styles.popup, { opacity: fadeAnimm }]}>
            <View>
              <View
                style={{
                  height: 50,
                  backgroundColor: "white",
                  flexDirection: "row",
                  alignItems: "center",
                  paddingRight: 15,
                  paddingLeft: 15,
                  borderTopRightRadius: 12,
                  justifyContent: "center",
                  borderTopLeftRadius: 12,
                }}
              >
                <Text
                  // allowFontScaling={false}
                  style={{
                    color: COLORS.primary,
                    fontSize: 20,
                    textAlign: "center",
                    lineHeight: 30,
                    alignSelf: "center",
                    marginTop: 5,
                    fontFamily: "Poppins-SemiBold",
                    fontWeight: Platform.OS === "web" ? 0 : "600",
                  }}
                >
                  {t("Language")}
                </Text>
                <TouchableOpacity
                  activeOpacity={0.7}
                  onPress={hidePopup3}
                  style={{
                    height: 35,
                    width: 35,
                    position: "absolute",
                    right: -10,
                    bottom: 30,
                  }}
                >
                  <Image
                    source={crossCircle}
                    style={{ height: 35, width: 35, resizeMode: "contain" }}
                  />
                </TouchableOpacity>
              </View>
            </View>
            <FlatList
              showsVerticalScrollIndicator={false}
              style={{ paddingRight: 10, paddingLeft: 10 }}
              data={Object.keys(languageResources)}
              renderItem={({ item }) => (
                <Pressable
                  style={{
                    borderBottomWidth: 0.5,
                    borderColor: "#6F74793D",
                    flexDirection: "row",
                    alignItems: "center",
                    paddingLeft: 20,
                    paddingVertical: 6.7,
                    justifyContent: "space-between",
                  }}
                  onPress={() => {
                    setSelectedItem(item),
                      togglePasswordSection2(),
                      changeLng(item),
                      hidePopup3();
                  }}
                >
                  <View style={{ flexDirection: "row" }}>
                    <Image
                      resizeMode="contain"
                      source={
                        item === "en"
                          ? images.flags.england
                          : item === "ko"
                          ? images.flags.korea
                          : item === "ch"
                          ? images.flags.china
                          : item === "fr"
                          ? images.flags.france
                          : item === "sp"
                          ? images.flags.spain
                          : null
                      }
                      style={{ height: 30, width: 30, marginRight: 15 }}
                    />
                    <Text
                      // allowFontScaling={false}
                      style={{
                        fontSize: 19,
                        fontFamily: FONTS.PoppinsSemiBold,
                        color: selectedItem === item ? COLORS.primary : "black",
                      }}
                    >
                      {item === "en"
                        ? "English"
                        : item === "ko"
                        ? "Korean"
                        : item === "ch"
                        ? "Chinese"
                        : item === "fr"
                        ? "French"
                        : item === "sp"
                        ? "Spanish"
                        : null}
                    </Text>
                  </View>
                  <Image
                    source={selectedItem === item ? FillDot : UnfillDot}
                    style={{
                      height: 14,
                      width: 14,
                      resizeMode: "contain",
                      marginRight: 15,
                    }}
                  />
                </Pressable>
              )}
            />
          </Animated.View>
        </>
      )}
    </ScrollView>
  );
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: COLORS.white,
    padding: 20,
    alignItems: "center",
  },
  headerView: {
    width: "100%",
    flexDirection: "row",
    marginTop: Platform.OS === "web" ? 0 : 30,
    alignItems: "center",
    justifyContent: "center",
  },
  backButton: {
    height: 24,
    width: 24,
    position: "absolute",
    left: 0,
    top: 7,
  },
  logo: {
    width: 66,
    height: 64,
  },
  welcomeText: {
    fontWeight: "700",
    fontSize: 15,
    letterSpacing: 0.84,
    color: COLORS.black,
    marginTop: Platform.OS === "web" ? 20 : 30,
  },
  instructionsText: {
    fontFamily: FONTS.PoppinsMedium,
    fontSize: 14,
    fontWeight: "500",
    color: COLORS.black,
    marginBottom: 45,
  },
  input: {
    width: "100%",
    maxWidth: 436,
    minWidth: 325,
    borderWidth: 1,
    borderRadius: 8,
    height: 50,
    paddingLeft: 20,
    paddingRight: 20,
    marginTop: 14,
    borderColor: COLORS.primarybordercolor,
    fontFamily: FONTS.PoppinsMedium,
  },
  passwordInput: {
    width: "100%",
  },
  inputWeb: {
    outlineColor: "white",
    outlineStyle: "none",
  },
  errorText: {
    fontSize: 10,
    color: "red",
    fontFamily: FONTS.PoppinsMedium,
    marginVertical: 5,
    textAlign: "left",
  },
  passwordContainer: {
    flexDirection: "row",
    width: Platform.OS === "web" ? "50%" : width - 92,
    maxWidth: 436,
    minWidth: 325,
    alignItems: "center",
  },

  passwordToggle: {
    position: "absolute",
    height: 20,
    width: 20,
    right: "5%",
    top: 28,
  },
  toggleImage: {
    height: 20,
    width: 20,
  },
  rememberMeContainer: {
    flexDirection: "row",
    width: Platform.OS === "web" ? "50%" : width - 92,
    maxWidth: 436,
    minWidth: 325,
    marginTop: 10,
    alignItems: "center",
  },
  checkbox: {
    height: 16,
    width: 16,
    marginRight: -6,
  },
  rememberMeText: {
    fontFamily: FONTS.PoppinsRegular,
    fontSize: 14,
    fontWeight: "400",
    color: COLORS.primarygraycolor,
    marginLeft: "3%",
  },
  forgotPassword: {
    marginTop: 33,
  },
  forgotPasswordText: {
    textDecorationLine: "underline",
    fontFamily: FONTS.PoppinsLight,
    fontWeight: "300",
    fontSize: 14,
    color: COLORS.primary,
  },
  signInButton: {
    marginTop: Platform.OS === "web" ? 20 : 37,
    width: Platform.OS === "web" ? "50%" : width - 92,
    maxWidth: 436,
    minWidth: 325,
    borderRadius: 46,
    height: 50,
    backgroundColor: COLORS.primary,
    justifyContent: "center",
    alignItems: "center",
  },
  signInButtonText: {
    fontFamily: FONTS.PoppinsMedium,
    fontSize: 14,
    fontWeight: "500",
    color: COLORS.white,
  },
  orContainer: {
    flexDirection: "row",
    width: Platform.OS === "web" ? 283 : width - 92,
    justifyContent: "space-between",
    height: 30,
    alignItems: "center",
    marginTop: 34,
  },
  divider: {
    height: 1,
    width: 79,
    backgroundColor: COLORS.primarybordercolor,
  },
  orText: {
    fontFamily: FONTS.PoppinsLight,
    fontSize: 14,
    fontWeight: "300",
    color: COLORS.primarygraycolor,
  },
  googleButton: {
    height: 50,
    width: Platform.OS === "web" ? "50%" : width - 92,
    maxWidth: 436,
    minWidth: 325,
    borderRadius: 46,
    borderWidth: 1,
    borderColor: COLORS.primarybordercolor,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    marginTop: 13,
  },
  googleIcon: {
    height: 20,
    width: 20,
    marginRight: 20,
  },
  googleButtonText: {
    fontFamily: FONTS.PoppinsMedium,
    fontSize: 14,
    fontWeight: "500",
    color: COLORS.black,
  },
  signUpPrompt: {
    marginTop: Platform.OS === "web" ? 48 : "10%",
  },
  signUpText: {
    fontFamily: FONTS.PoppinsRegular,
    fontSize: 14,
    fontWeight: "500",
    color: COLORS.primarygraycolor,
  },
  signUpLink: {
    color: COLORS.primary,
  },
  blurBackground: {
    ...StyleSheet.absoluteFillObject,
  },
  popup: {
    position: "absolute",
    backgroundColor: "white",
    padding: 0,
    borderRadius: 12,
    elevation: 5,
    alignSelf: "center",
    height: 312,
    width: Platform.OS === "web" ? "20%" : "70%",
    maxWidth: 400,
    minWidth: Platform.OS === "web" ? 300 : undefined,
    borderWidth: 0,
    top: Platform.OS === "web" ? 150 : 300,
  },
});
export default Signin;
