import "react-native-gesture-handler";
import { registerRootComponent } from "expo";
import App from "./App";
import { Platform, Text } from "react-native";
import messaging from "./firebaseMessaging";
import { useEffect } from "react";
// Text.defaultProps = {
//   ...Text.defaultProps,
//   maxFontSizeMultiplier: 2,
// };
// Text.defaultProps = { maxFontSizeMultiplier: 1 };


if (Platform.OS !== "web") {
  import("@react-native-firebase/messaging")
    .then((module) => {
      const messaging = module.default;
      messaging().setBackgroundMessageHandler(async (remoteMessage) => {
        console.log("Message handled in the background!", remoteMessage);
      });
    })
    .catch((err) => {
      console.error("Failed to load @react-native-firebase/messaging", err);
    });
} else {
  console.log("Firebase messaging is not available on the web");
}

registerRootComponent(App);
